let lastScrollTop = 0;
const SCROLL_THRESHOLD = 50;

const header = document.getElementById('header');
const checkoutOrderReview = document.querySelector('.checkout-order-review');
const megaMenu = document.querySelector('.megamenu');

function toggleScrollClasses(element, isScrollingDown) {
    if (!element) return;

    if (isScrollingDown) {
        element.classList.remove('-scroll-up');
        element.classList.add('-scroll-down');
    } else {
        element.classList.remove('-scroll-down');
        element.classList.add('-scroll-up');
    }
}

function toggleFixedClass(element, scrollTop) {
    if (!element) return;

    if (scrollTop > 0) {
        element.classList.add('-is-fixed');
    } else {
        element.classList.remove('-is-fixed');
        element.classList.remove('-scroll-up');
    }
}

window.addEventListener('load', () => {
    let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    toggleFixedClass(header, scrollTop);
    toggleFixedClass(checkoutOrderReview, scrollTop);
    toggleFixedClass(megaMenu, scrollTop);
});

window.addEventListener('scroll', () => {
    let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    let isScrollingDown =
        scrollTop > lastScrollTop && scrollTop > SCROLL_THRESHOLD;

    toggleScrollClasses(header, isScrollingDown);
    toggleScrollClasses(checkoutOrderReview, isScrollingDown);
    toggleScrollClasses(megaMenu, isScrollingDown);

    toggleFixedClass(header, scrollTop);
    toggleFixedClass(checkoutOrderReview, scrollTop);
    toggleFixedClass(megaMenu, scrollTop);

    lastScrollTop = scrollTop <= 0 ? 0 : scrollTop;
});
